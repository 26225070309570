.ui.segment.View.survey-info {
    flex: 1;
    overflow: auto;
}

.ui.segment.View.survey-picker {
    flex: none;
}

div.survey-header {
    display: flex;
    font-size: initial;
    font-weight: 700;
    margin: 20px;
    flex-direction: column;
    align-items: center;
}

div.survey-header>div>span {
    font-weight: 400;
}

.survey-q-and-a {
    margin: 20px;
}

.survey.pdf-friendly {
    max-width: 600px;
}


.survey-icon-and-title {
    display: flex;
    align-content: center;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;
}

.survey-icon-and-title>.question.icon {
    padding-top: 5px;
}

.survey-title {
    font-size: smaller;
    font-weight: 700;
}

.survey-question {
    font-size: 1.1em;
}

.survey-answer {
    font-size: 1.1em;
}

.survey-icon-and-title>table,
.survey-icon-and-title>table>thead>tr>th {
    border: 1px solid;
    text-align: start;
    font-size: 1.1em;
    border-spacing: 0px;
}