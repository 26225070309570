#root,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
  background: #62ddfc;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.not-implemented,
.ui.popup.visible.not-implemented {
  display: none !important;
}

.Page {
  display: flex;
  flex: 1 1;
  justify-content: flex-start;
  min-height: 250px;
  max-height: 100%;
}

.column {
  flex-direction: column;
}

.row {
  flex-direction: row;
}

.View,
.ui.segment.View {
  border: solid #62ddfc 0px;
  background: white;
  margin: 0px;
  margin-left: 20px;
  margin-top: 20px;
  border-radius: 10px;
  padding: 10px;
  flex: 1;
  box-shadow: 3px 3px 4px 0px darkgrey;
}

.column>.ui.segment.View {
  margin-right: 20px;
}

.ui.segment.View:first-child {
  margin-top: 20px;
}

.column>.ui.segment.View:last-child {
  margin-bottom: 20px;
}

.row>.ui.segment.View {
  margin-bottom: 20px;
}

.row>.ui.segment.View:last-child {
  margin-right: 20px;
}

.segment-content {
  height: 100%;
  padding: 10px;
  margin: 10px;
}