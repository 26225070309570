.Sidebar-menu {
    margin-top: 10px;
    transition: width 0.5s linear, visibility 0s;
}

.Sidebar-menu>.ui.list {
    font-size: xx-large;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px;
}

.Sidebar-menu>.ui.list>.item {
    margin: 5px;
}

.menu.transition.visible.transition.submenu {
    left: 60px;
    top: -10px;
    margin: 0;
    padding: 0;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    background: #62ddfc;
    border: none;
    border-radius: 10px;
    box-shadow: 3px 3px 4px 0px darkgrey;
}

.ui.dropdown .menu.transition.visible.submenu>.item {
    font-size: xx-large;
    margin: 5px;
    padding: 5px !important;
}

.ui.dropdown .menu.transition.visible.submenu .item>i.icon {
    margin: 0;
}

.mobile .closed {
    width: 0px;
    visibility: hidden;
}

.mobile .open {
    visibility: visible;
    width: 62px;
}