.mobile .ui.study-info {
    flex: 1;
}

.mobile .ui.study-info.extra-info {
    flex: 0;
}

.mobile .ui.study-info>.ui.form {
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
}

.data-menu {
    display: flex;
    font-size: xxx-large;
    justify-content: space-around;
    align-items: flex-end;
    margin-bottom: 20px;
    max-height: fit-content;
    margin-top: 20px;
}

.data-menu .ui.button {
    font-size: 1.7rem;
    border-radius: 10px;
}

.mobile .ui.segment.View.data-breakdown {
    height: calc(100% - 200px);
    width: 100%;
    max-width: fit-content;
    min-width: 200px;
}