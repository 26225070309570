.ui.segment.study-info {
    flex-basis: auto;
    min-width: 250px;
    display: flex;
    flex-direction: row;
    padding: 10px 20px;
    flex: none;
}

.ui.study-info>.ui.form {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ui.segment.data-overview>.data-table {
    flex: 1;
    padding: 30px;
    min-width: 300px;
    max-width: 400px;
}

th.ID {
    width: 80px;
}

.data-graphs {
    overflow: overlay;
    margin: 20px;
}

.ui.segment.View.study-data.graph {
    margin-left: 0px;
    margin-top: 0px;
    margin-bottom: 20px;
    margin-right: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background: #767676d6; */
}

.ui.segment.View.study-data.graph>span {
    font-size: initial;
    font-weight: bold;
    color: #62ddfc;
}

.ui.segment.View.study-data.graph:last-child {
    margin-left: 0px;
    margin-top: 0px;
    margin-bottom: 5px;
}

.ui.segment.View.data-breakdown {
    overflow: hidden;
    padding: 0;
    flex: none;
    min-width: 300px;
    width: min-content;
    max-width: 50%;
}

.ui.segment.View.data-breakdown>.ui.table {
    margin-top: 0;
    height: 100%;
    display: grid;
}

.ui.segment.View.data-breakdown>.ui.table>thead,
.ui.segment.View.data-breakdown>.ui.table>tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.ui.segment.View.data-breakdown>.ui.table>tbody {
    display: block;
    overflow: auto;
}

.user-extra-info {
    display: flex;
    align-items: center;
    flex: 1 1;
    width: 100%;
    align-content: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.user-extra-info .info {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.5em;
}

.user-extra-info .info img {
    width: 1.5em;
    margin-right: 0.5em;
}