.Messaging .ui.segment.View {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
}

.Messaging .ui.header {
    margin-bottom: 30px;
}

.Messaging .ui.segment.View .ui.form {
    padding: 15px;
}


.Messaging .ui.segment.View .ui.red.message {
    margin: 0 0.2em 0 0;
    padding: 0.78em 1.5em 0.78em;
    font-size: 1rem;
}