.App {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.body {
  display: flex;
  flex-direction: row;
  flex: 1;
  min-height: 0;
  min-width: 0;
  width: 100%;
}

i.icon {
  color: lightslategrey;
}

.outlet {
  flex: 1;
  display: flex;
  background: #eafcf8;
  border-top-left-radius: 15px;
  box-shadow: inset 2px 2px 8px 0px darkgrey;
}

.ui.form input:read-only {
  border: none;
  cursor: default;
}