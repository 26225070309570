.ui.segment.User-list {
    flex-basis: auto;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    flex: none;
}

.ui.active.dimmer {
    opacity: 0.8;
}

.User-list .list {
    flex: 1;
    overflow: auto;
}

.list-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
}

.User-info {
    overflow: auto;
}

.User-info.admin {
    display: flex;
    flex-direction: column;
    align-content: space-around;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.User-info.admin .ui.form {
    width: 330px;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
}

.content .content {
    font-size: smaller;
}

.ui.form .error.message {
    display: block;
}