.App-header {
    height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: white;
    font-size: 2em;
    padding: 0.3em;
    max-height: 100vh;
    align-items: center;
}

.menu-icon {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    padding-top: 5px;
}

.mobile .App-header {
    padding: 0;
}

.mobile .title {
    flex: 1;
    font-size: 0.7em;
}