.ui.segment.study-info {
    flex-basis: auto;
    min-width: 250px;
    display: flex;
    flex-direction: row;
    padding: 10px 20px;
    flex: none;
}

.ui.study-info>.ui.form {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ui.segment.data-overview {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    overflow: auto;
}

.Page.data-graphs.row.data-tables>.data-table {
    border: solid #62ddfc 0px;
    background: white;
    margin: 0px;
    margin-right: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 3px 3px 4px 0px darkgrey;
    min-width: 300px;
    height: fit-content;
}

th.ID {
    width: 80px;
}

.data-tables {
    justify-content: center;
    flex-wrap: wrap;
}

.mobile .Page.data-graphs.row.data-tables {
    flex-direction: column;
    overflow: unset;
    overflow-x: auto;
    justify-content: flex-start;
}

.mobile .study-data .ui.study-info {
    max-height: fit-content;
    flex: 0;
    max-width: 100%;
}

.mobile .study-data .ui.segment.study-info .fields {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 0 0 1em;
}

.mobile .study-data .ui.segment.study-info .fields>.field {
    padding: 0;
}

.mobile .data-tables .ui.table {
    margin-top: 0;
    height: 100%;
    display: grid;
}

.mobile .data-tables .ui.table>thead,
.mobile .data-tables .ui.table>tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.mobile .data-tables .ui.table>tbody {
    display: block;
    overflow: auto;
}

.mobile .ui.data-table table.ui.celled.unstackable.table {
    margin: 10px;
    height: calc(100% - 25px);
    display: grid;
    width: calc(100% - 20px);
}

.mobile .Page.data-graphs.row.data-tables>.data-table {
    width: 100%;
    max-width: fit-content;
    margin: 0;
    overflow: hidden;
    padding: 0;
    flex: none;
    min-width: 300px;
    padding-bottom: 15px;
    margin: 0 15px 0 15px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    height: fit-content;
    max-height: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.mobile th.ID {
    width: inherit;
}

.mobile .data-graphs {
    margin: 0px;
    margin-left: 10px;
    margin-top: 15px;
}