.ui.segment.Login-view {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
}

.ui.segment.Login-view>.ui.form {
    min-width: 190px;
    width: 30%;
    margin-bottom: 20px;
}