.ui.active.modal.hrv-modal.study-users {
    height: 60%;
    min-height: 300px;
    display: flex !important;
    flex-direction: column;
}

.ui.modal.hrv-modal.study-users>.content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 50%;
    flex: 1;
}

.user-selection {
    padding: 10px 30px 10px 30px;
    margin: 20px;
    font-size: larger;
    flex: 1;
}

.ui.list.user-selection {
    border: solid lightgray;
    border-width: 1px;
    border-radius: 5px;
    margin: 0px;
    height: 100%;
    overflow-y: auto;
    font-size: large;
    padding: 5px;
}

.ui.list.user-selection>.item {
    cursor: pointer;
}

.ui.list.user-selection>.item:hover {
    font-weight: bold;
}

.flex {
    display: flex;
    flex: 1;
}

.overflow {
    overflow-x: auto;
}